<script setup>
const props = defineProps({
  isDangerous: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: null,
  },
  size: {
    type: String,
    default: 'md',
    validator: value => ['sm', 'md'].includes(value),
  },
})
</script>

<template>
  <div
    class="inline-flex items-center gap-1 rounded-full border"
    :class="{
      'border-error bg-error/5 text-error': props.isDangerous,
      'border-gray-400 dark:border-base-content': !props.isDangerous,
      'p-1 text-sm': props.size === 'sm',
      'p-1 px-2': props.size === 'md',
    }"
  >
    <template v-if="count">
      {{ count }}
      <span class="font-mono text-xs opacity-60">x</span>
    </template>
    <slot />
    <div v-if="props.isDangerous" class="h-3 text-red-500">
      <svg
        aria-hidden="true"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        role="img"
        viewBox="0 0 24 24"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="m12,3l-8,8l8,8l8,-8l-8,-8zm-9.9,8l9.9,9.9l9.9,-9.9l-9.9,-9.9l-9.9,9.9z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>
