import { ref } from 'vue'

import { Authentication, type User } from '@sortera/firebase-auth'

import { firebaseApp } from '@/plugins/db.js'

const auth = new Authentication(
  {
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    redirectUri: window.location.origin + '/autentisering/logga-in',
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AUTH_TENANT_ID}`,
    scopes: [import.meta.env.VITE_AUTH_GATEWAY_SCOPE],
    tenantId: import.meta.env.VITE_AUTH_TENANT_ID,
  },
  firebaseApp
)
const account = ref<User | null>(null)

async function initializeAuth(options?: Parameters<typeof auth.initializeAuth>[0]) {
  if (account.value != null) {
    return
  }
  account.value = await auth.initializeAuth(options)
  return account.value
}

function login(): Promise<void> {
  return auth.login()
}

async function logout(): Promise<void> {
  account.value = null
  await auth.logout()
}

async function getAccessToken(): Promise<string> {
  return await auth.getAccessToken()
}

export function useAuth() {
  return {
    account,
    initializeAuth,
    login,
    logout,
    getAccessToken,
  }
}
